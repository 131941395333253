<template>
  <div class="first-login-container">
    <!-- 登录 -->
    <div class="login">
      <!-- title  -->
      <h3 class="title">
        {{ translateTitle("绑定验证") }}
      </h3>

      <!-- tab切换 -->
      <div class="tab-change">
        <el-tabs v-model="currentType" class="tabs" @tab-click="onTabChange">
          <el-tab-pane key="0" name="0">
            <span slot="label">
              {{ translateTitle("绑定谷歌") }}
            </span>
          </el-tab-pane>
          <el-tab-pane key="1" name="1">
            <span slot="label">{{ translateTitle("绑定邮箱") }}</span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="login-form">
        <el-form
          ref="verifyForm"
          :rules="formRules"
          :model="verifyData"
          label-position="top"
          @submit.native.prevent
        >
          <div v-if="currentType == 0">
            <div class="infoTip">
              <div class="dots">
                <span v-for="(item, index) in 3" :key="index"></span>
                <i class="line"></i>
              </div>
              <div class="content-tip">
                <p class="title">
                  {{
                    translateTitle("1.下载“Google Authenticator (谷歌验证器)”")
                  }}
                </p>
                <div class="download">
                  <div
                    class="appDownload"
                    v-for="(item, index) in appDownload"
                    :key="index"
                  >
                    <img :src="item.img" :alt="item.name" class="apk" />
                    <div class="qr-code">
                      <img :src="item.apk" :alt="item.name + '二维码'" />
                    </div>
                  </div>
                </div>
                <p class="title">
                  {{
                    translateTitle(
                      "2.打开谷歌验证器，扫描下方二维码或手动输入下述密钥添加验证令牌"
                    )
                  }}
                </p>
                <p class="tip">
                  {{
                    translateTitle(
                      "密钥用于手机更换或遗失时找回谷歌验证器，绑定前请务必将下述密钥备份保存"
                    )
                  }}
                </p>
                <div class="qr_code">
                  <qrcode-vue
                    class="qr_code_canvas"
                    v-show="googleCode"
                    :value="googleCode"
                    :size="90"
                    level="H"
                  ></qrcode-vue>
                  <p>
                    <span style="display:block">{{
                      translateTitle("密钥：")
                    }}</span>
                    <span class="secret-key">{{ secret }}</span>
                    <i
                      class="iconfont iconicon-copy"
                      v-clipboard:copy="secret"
                      v-clipboard:success="copySuccess"
                      v-clipboard:error="copyError"
                    ></i>
                  </p>
                </div>
                <p class="title">
                  {{
                    translateTitle("3.添加成功后，将谷歌验证码填入下方输入框")
                  }}
                </p>
              </div>
            </div>
            <div class="google-title">{{ translateTitle("谷歌验证码") }}</div>
            <div class="edit-item">
              <el-form-item prop="google">
                <InputNumber
                  v-model="verifyData.google"
                  autocomplete="off"
                  :placeholder="translateTitle('请输入谷歌验证码')"
                  maxlength="6"
                ></InputNumber>
                <!-- <span class="common-operate paste" @paste="onPaste">粘贴</span> -->
              </el-form-item>
            </div>
          </div>
          <div v-else>
            <div class="edit-item">
              <el-form-item prop="email">
                <el-input
                  v-model.trim="verifyData.email"
                  :placeholder="translateTitle('请输入要绑定的邮箱')"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </div>
            <div class="edit-item add-margin">
              <el-form-item prop="code">
                <InputNumber
                  v-model="verifyData.code"
                  autocomplete="off"
                  :placeholder="translateTitle('请输入邮箱验证码')"
                  maxlength="6"
                ></InputNumber>
              </el-form-item>
              <Code :counting.sync="counting" @count="onSendCode"></Code>
            </div>
          </div>
          <div class="edit-item">
            <el-button
              class="login-btn theme-btn"
              type="primary"
              :loading="loginLoading"
              @click="onVerify"
              >{{ translateTitle("登 录") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
    <!-- 版权 -->
    <div class="copyright">
      <span>Copyright &copy; 2021, All Rights Reserved</span>
    </div>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import Code from "./components/Code";
import InputNumber from "@/components/common/InputNumber";
import { showMessage } from "@/components/dialog/message";
import QrcodeVue from "qrcode.vue";
import {
  getGoogleSecretFirst,
  sendEmailCode,
  bindGoogleAuthApplication,
  bindEmailFrist,
} from "@/model/user/account";

export default {
  data() {
    const accountInfo = this.$route.query.accountInfo;
    // if (!accountInfo) {
    //   this.$router.push({
    //     path: "/login",
    //   });
    // }
    return {
      counting: false,
      secret: "",
      appDownload: [
        {
          img: require("./../../assets/img/Apple-store.png"),
          apk: require("./../../assets/img/Apple-qrCode.png"),
          name: "Apple",
        },
        {
          img: require("./../../assets/img/Android-market.png"),
          apk: require("./../../assets/img/Android-qrCode.png"),
          name: "Android",
        },
        {
          img: require("./../../assets/img/Google-play.png"),
          apk: require("./../../assets/img/Google-qrCode.png"),
          name: "Google",
        },
      ],
      loginLoading: false, // 登录loading
      currentType: "0", // 当前选项
      verifyData: {
        // 登录数据
        code: "",
        email: "",
        google: "",
      },
      formRules: {
        code: [
          {
            required: true,
            message: this.translateTitle("请输入邮箱验证码"),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`验证码应为 6 位数字`),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.translateTitle(`请输入邮箱`),
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
            message: this.translateTitle(`邮箱格式不正确`),
            trigger: "blur",
          },
        ],
        google: [
          {
            required: true,
            message: this.translateTitle(`请输入谷歌验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`验证码应为 6 位数字`),
            trigger: "blur",
          },
        ],
      },
      accountInfo,
    };
  },
  mounted() {
    this.getGoogleSecret();
  },
  computed: {
    googleCode() {
      if (this.accountInfo) {
        return `otpauth://totp/${this.accountInfo.username})?issuer=Topcredit&secret=${this.secret}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    translateTitle,
    onPaste(e) {
      var data = null;
      var clipboardData = e.clipboardData; // IE
      if (!clipboardData) {
        //chrome
        clipboardData = e.originalEvent.clipboardData;
      }

      data = clipboardData.getData("Text");
      this.verifyData.google = data;
    },
    onSendCode() {
      if (!this.verifyData.email) {
        showMessage({ message: this.translateTitle(`请输入邮箱`) });
        return;
      }
      const data = {
        email: this.verifyData.email,
      };
      sendEmailCode(data).then((rs) => {
        if (rs.status == 200) {
          showMessage({
            message: this.translateTitle(`发送成功`),
            type: "success",
          });
          this.counting = true;
        }
      });
    },
    // 复制成功
    copySuccess() {
      showMessage({
        message: this.translateTitle(`内容复制成功`),
        type: "success",
      });
    },
    // 复制失败
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    // 获取谷歌验证
    getGoogleSecret() {
      getGoogleSecretFirst().then((res) => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          // showMessage({ message: res.msg });
        }
      });
    },
    onVerify() {
      this.$refs.verifyForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          if (this.currentType == 0) {
            const data = {
              google_secret: this.secret,
              google_code: this.verifyData.google,
            };
            bindGoogleAuthApplication(data).then((rs) => {
              if (rs.status == 200) {
                this.handleSuccess(rs.data);
                return;
              }
              this.loginLoading = false;
            });
          } else {
            const data = {
              email: this.verifyData.email,
              email_code: this.verifyData.code,
            };
            bindEmailFrist(data).then((rs) => {
              if (rs.status == 200) {
                this.handleSuccess(rs.data);
                return;
              }
              this.loginLoading = false;
            });
          }
        }
      });
    },
    async handleSuccess(data) {
      showMessage({
        message: this.translateTitle(`登录成功`),
        type: "success",
      });
      //写入cookie, 邮箱登录 , 手机登录  注册一致
      location.href = "/";
      // this.$store.dispatch("getUserInfo").then((res) => {
      //   let jump = this.$route.params.jump;
      //   if (jump) {
      //     location.href = jump;
      //     return;
      //   } else {
      //   }
      // });
    },
    onTabChange() {
      this.$refs.verifyForm.resetFields();
    },
  },
  components: { InputNumber, Code, QrcodeVue },
};
</script>
<style lang="scss">
.first-login-container {
  width: 100%;
  min-width: 1260px;
  text-align: left;
  background: #1d1e23 url(../../assets/img/login_bg.png) no-repeat fixed top;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 70px;
  flex: 1;
  height: calc(100vh - 60px);
  min-height: 900px;

  /* 登录 */
  .login {
    width: 500px;
    min-height: 268px;
    margin: 0px auto 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    position: relative;
    z-index: 5;
    padding-bottom: 36px;

    h3 {
      color: #333;
      font-size: 20px;
      font-weight: 500;
      padding: 23px 31px 29px;
      padding-bottom: 0;
    }

    .tab-change {
      height: 54px;
      margin-top: 10px;
      display: flex;
      justify-content: left;
      padding-left: 33px;

      .tabs {
        .el-tabs__nav-wrap {
          &:after {
            background-color: transparent;
          }
          /* 选项 */
          .el-tabs__item {
            color: #354052;
            font-size: 16px;
            font-weight: 500;
          }
          /* 底部条 */
          .el-tabs__active-bar {
            background-color: #ffffff;
            content: "";
            height: 3px;

            &:after {
              content: "";
              height: 3px;
              width: 20px;
              background-color: #d60c30;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    /* 表单 */
    .login-form {
      .el-form {
        input {
          border-radius: 2px;
          &:focus {
            border-color: #c61b3d;
          }
        }

        /* 每一项 */
        .el-form-item {
          flex: 1;
          padding-top: 3px;
        }
        .edit-item {
          position: relative;
          display: flex;
          margin: 0 60px;
        }
        .number {
          flex: 1;
        }
        .paste {
          position: absolute;
          right: 19px;
          top: 0;
          user-select: none;
        }
        .sendcode {
          margin-top: 3px;
          .count-down {
            top: 36%;
            span {
              font-size: 14px;
            }
          }
        }

        /* 登录 */
        .login-btn {
          margin: 10px auto 0;
          width: 240px;
          height: 42px;
          color: #fff;
          font-size: 16px;
          line-height: 42px;
          text-align: center;
          border-radius: 2px;
          background-image: linear-gradient(79deg, #c61b4e 21%, #ee244c 82%);
          padding: 0;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  /* 版权 */
  .copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
}
.google-title {
  // width: 70px;
  height: 26px;
  color: #354052;
  font-size: 14px;
  font-weight: 500;
  margin-left: 61px;
  user-select: none;
}
.infoTip {
  display: flex;
  margin: 0 40px 26px 30px;

  /* 点 */
  .dots {
    width: 37px;
    position: relative;
    span {
      display: block;
      width: 17px;
      height: 17px;
      background: url("./../../assets/img/dot.png") no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      z-index: 2;
      &:first-child {
        // top: 9px;
      }
      &:nth-child(2) {
        top: 85px;
      }
      &:nth-child(3) {
        top: 328px;
      }
    }
    /* 线 */
    .line {
      display: block;
      width: 326px;
      height: 1px;
      transform: rotateZ(90deg);
      background-color: #b4bac6;
      position: absolute;
      top: 178px;
      left: -155px;
    }
  }
  /* 提示内容 */
  .content-tip {
    margin-left: 15px;
    p {
      color: #717985;
      font-size: 14px;
      &.tip {
        color: #f04a5a;
        margin-top: 10px;
        font-weight: 500;
      }
    }
    .download {
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .appDownload {
        width: 106px;
        height: 30px;
        margin-right: 7px;
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          .qr-code {
            display: block;
          }
        }
        .apk {
          width: 100%;
          height: 100%;
          display: block;
        }
        .qr-code {
          display: none;
          position: absolute;
          right: -120px;
          top: -30px;
          width: 120px;
          height: 120px;
          padding: 10px;
          background-color: #fff;
          border: 1px solid #e7ecf0;
          border-radius: 2px;
          transition: all 0.3s;
          box-sizing: border-box;
          z-index: 3;
          &:before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            top: 40px;
            left: -6px;
            transform: rotate(-45deg);
            background: #fff;
            border-top: 1px solid #e7ecf0;
            border-left: 1px solid #e7ecf0;
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    .qr_code {
      height: 155px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 30px;
      box-sizing: border-box;
      .secret-key {
        color: #354052;
      }
      .qr_code_canvas {
        width: 100px;
        height: 100px;
        border: 5px solid #ecedf1;
        margin-right: 20px;
        canvas {
          padding: 5px;
        }
      }
      i {
        cursor: pointer;
        color: #9aa1b1;
        margin-left: 25px;
        &:hover {
          color: #9aa1b1;
        }
      }
    }
  }
}
</style>
